import React, {useEffect, forwardRef} from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faSlash, faChevronDown, faMinus, faChevronUp} from "@fortawesome/free-solid-svg-icons";
import {useHistory } from "react-router-dom";

const ReadingRules = forwardRef((props, ref) => {

  const history = useHistory();

  useEffect(() => {

  }, []);

  function handleClick() {
    history.push('/');
  }

  return (
    <main style={{backgroundColor: '#222'}}>
      <div>
        <div className={'back-to-page'}>
          <div onClick={() => handleClick()}><FontAwesomeIcon icon={faChevronDown}/></div>
        </div>
        <div id={'list-words-admin'}>
          <div className={'d-flex justify-content-center align-items-center flex-column mb-1'}>
            <div className={'d-flex align-items-center'}>
              <FontAwesomeIcon icon={'book-open'}/>
              <div className={'bold ml-1'}>Reading Rules</div>
            </div>
            <div className={'d-flex'}>
              <div className={'phonetic'}></div>
              <div className={'phonetic ml-1'}></div>
            </div>
          </div>
          <div className={'list'}>
            <div>
              <div className={'title-rules'}>Without Tones Marks And Final Consonants</div>

              <div>
                <div className={'word'}><b>Middle C + Short V : </b> <span>Low Tone <FontAwesomeIcon icon={faSlash}/></span></div>
                <div className={'word'}><b>Middle C + Long V : </b> <span>Mid Tone <FontAwesomeIcon icon={faMinus}/></span></div>
                <div className={'word'}><b>High C + Short V : </b> <span>Low Tone <FontAwesomeIcon icon={faSlash}/></span></div>
                <div className={'word'}><b>High C + Long V : </b> <span>Rising Tone <FontAwesomeIcon icon={faChevronDown}/></span></div>
                <div className={'word'}><b>Low C + Short V : </b> <div className={'d-flex'}>High Tone <div className={'rotate-90'}><FontAwesomeIcon icon={faSlash}/></div></div></div>
                <div className={'word'}><b>Low C + Long V : </b> <span>Mid Tone <FontAwesomeIcon icon={faMinus}/></span></div>
              </div>
            </div>

            <div className={'mt-2'}>
              <div className={'title-rules'}>With Tone Marks</div>
              <table className={'table-rules even'}>
                <tbody>
                <tr>
                  <th colSpan={5}>Middle C.</th>
                </tr>
                <tr>
                  <td className={'rule'}><b>ปา</b><br/> <span><FontAwesomeIcon icon={faMinus}/></span></td>
                  <td className={'rule'}><b>ป่า</b><br/> <span><FontAwesomeIcon icon={faSlash}/></span></td>
                  <td className={'rule'}><b>ป้า</b><br/> <span><FontAwesomeIcon icon={faChevronUp}/></span></td>
                  <td className={'rule'}><b>ป๊า</b><br/> <div className={'rotate-90'}><FontAwesomeIcon icon={faSlash}/></div></td>
                  <td className={'rule'}><b>ป๋า</b><br/> <span><FontAwesomeIcon icon={faChevronDown}/></span></td>
                </tr>

                <tr>
                  <th colSpan={5}>High C.</th>
                </tr>
                <tr>
                  <td></td>
                  <td className={'rule'}><b>ขา</b><br/> <span><FontAwesomeIcon icon={faChevronDown}/></span></td>
                  <td className={'rule'}><b>ข่า</b><br/> <span><FontAwesomeIcon icon={faSlash}/></span></td>
                  <td className={'rule'}><b>ข้า</b><br/> <span><FontAwesomeIcon icon={faChevronUp}/></span></td>
                  <td></td>
                </tr>

                <tr>
                  <th colSpan={5}>Low C.</th>
                </tr>
                <tr>
                  <td></td>
                  <td className={'rule'}><b>คา</b><br/> <span><FontAwesomeIcon icon={faMinus}/></span></td>
                  <td className={'rule'}><b>ค่า</b><br/> <span><FontAwesomeIcon icon={faChevronUp}/></span></td>
                  <td className={'rule'}><b>ค้า</b><br/> <div className={'rotate-90'}><FontAwesomeIcon icon={faSlash}/></div></td>
                  <td></td>
                </tr>
                </tbody>
              </table>
            </div>

            <div className={'mt-2'}>
              <div className={'title-rules'}>With Final Consonant</div>

              <table className={'table-rules odd'}>
                <tbody>
                <tr>
                  <th rowSpan={2}>Initial Consonant</th>
                  <th colSpan={2}>Short Vowel</th>
                  <th colSpan={2}>Long Vowel</th>
                </tr>
                <tr>
                  <td>Dead Final</td>
                  <td>Alive Final</td>
                  <td>Dead Final</td>
                  <td>Alive Final</td>
                </tr>

                <tr>
                  <td>Middle C.</td>
                  <td><FontAwesomeIcon icon={faSlash}/></td>
                  <td><FontAwesomeIcon icon={faMinus}/></td>
                  <td><FontAwesomeIcon icon={faSlash}/></td>
                  <td><FontAwesomeIcon icon={faMinus}/></td>
                </tr>

                <tr>
                  <td>High C.</td>
                  <td><FontAwesomeIcon icon={faSlash}/></td>
                  <td><FontAwesomeIcon icon={faChevronDown}/></td>
                  <td><FontAwesomeIcon icon={faSlash}/></td>
                  <td><FontAwesomeIcon icon={faChevronDown}/></td>
                </tr>

                <tr>
                  <td>Low C.</td>
                  <td className={'rotate-90'}><FontAwesomeIcon icon={faSlash}/></td>
                  <td><FontAwesomeIcon icon={faMinus}/></td>
                  <td><FontAwesomeIcon icon={faChevronUp}/></td>
                  <td><FontAwesomeIcon icon={faMinus}/></td>
                </tr>

                <tr>
                  <td colSpan={5}>Alive Final : น / ม / ย / ว / ง</td>
                </tr>

                <tr>
                  <td colSpan={5}>Dead Final : ก / บ / ด</td>
                </tr>
                </tbody>
              </table>
            </div>


            </div>
        </div>
      </div>
    </main>
  );
});

export default ReadingRules;