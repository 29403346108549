import React from 'react';

function Header() {

  return (
    <header>
      Learn Thai <img src={'/thai-flag.png'} alt="Logo" />
    </header>
  );
}

export default Header;