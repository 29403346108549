import React, {useEffect, useState} from 'react';
import {useParams, useHistory } from "react-router-dom";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faChevronLeft, faCopy} from "@fortawesome/free-solid-svg-icons";
import Api from "../services/Api";
import {Loader} from "../components/Loader";

const Word = (props) => {

  let { idWord, idCategory } = useParams();
  const [categories, setCategories] = useState(props.categories);
  const [category, setCategory] = useState([]);
  const history = useHistory();
  const [word, setWord] = useState([]);
  const [loading, setLoading] = useState(false);
  const [copiedToClipboard, setCopiedToClipboard] = useState(false);
  const [animation, setAnimation] = useState(false);

  useEffect(() => {
    setCategories(props.categories);

    if(idCategory) {
      setCategory(props.categories.find(x => x.id === parseInt(idCategory)));
    } else {
      setCategory([]);
    }
  }, [props.categories]);

  useEffect(() => {
    if(idCategory) {
      setCategory(categories.find(x => x.id === parseInt(idCategory)));
    } else {
      setCategory([]);
    }

    if(idWord) {
      getWord(idWord);
    }
  }, [idWord, idCategory]);

  async function getWord(id) {
    setLoading(true);
    const word = await Api.getWord(id);
    if (word.error) {
      setLoading(false);
    } else {
      setLoading(false);
      setWord(word.data);
    }
  }

  function copyToClipboard() {
    setCopiedToClipboard(true);
    setTimeout(function() {
      setAnimation(true);

      setTimeout(function() {
        setAnimation(false);
        setTimeout(function(){ setCopiedToClipboard(false); }, 100);
      }, 1000);

      }, 100);
  }

  return (
    <main style={category ? {backgroundColor: category.color} : {}}>
      <div>
        <div className={'back-to-page'}><div onClick={() => history.goBack()}><FontAwesomeIcon icon={faChevronLeft}/></div></div>
        <div className={'card-word position-relative'}>
          <div className={'english'}>{ word.english }</div>
          <div className={'phonetics'}>{ word.phonetic }</div>
          <div className={'thai'}>{ word.thai }</div>

          <div className={'btn-copy-to-clipboard'} onClick={() => {navigator.clipboard.writeText(word.thai); copyToClipboard()}}><FontAwesomeIcon icon={faCopy}/></div>
        </div>
        {copiedToClipboard &&
          <div className={'copied-to-clipboard' + (animation ? ' show' : '')}>Thai word copied !</div>
        }
      </div>
      <Loader loading={loading}/>
    </main>
  );
};

export default Word;