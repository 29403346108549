import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPen, faTrash, faEye} from "@fortawesome/free-solid-svg-icons";
import {useHistory} from "react-router-dom";
import {useUserContext} from "../context/UserContext";
import { useLocation } from 'react-router-dom';

const Word = (props) => {

  const {user} = useUserContext();
  const history = useHistory();
  const location = useLocation();
  const [hideThai, setHideThai] = useState(props.hideThai);

  useEffect(() => {
    setHideThai(props.hideThai);
  }, [props]);

  function handleClick() {
    if(location.pathname.startsWith('/category')) {
      history.push(location.pathname + '/words/' + props.word.id);
    } else if(location.pathname.startsWith('/account')) {
      history.push('/account/words/' + props.word.id);
    } else {
      history.push('/search/words/' + props.word.id);
    }
  }

  return (
    <div className={'word cursor-pointer'} onClick={() => setHideThai(!hideThai)}>
      <div>
        {props.word.english} <br/>
        {(!hideThai) && <div onClick={(e) => e.stopPropagation()}>{props.word.phonetic} <span className={'thai'}> ({props.word.thai}) </span></div> }
      </div>
      {((user.email && location.pathname.startsWith('/account')) &&
      <div className={'d-flex actions'}>
        <div>
          <div onClick={() => handleClick()}>
            <FontAwesomeIcon icon={faPen}/>
          </div>
        </div>
        <div onClick={props.openModal}>
          <FontAwesomeIcon icon={faTrash}/>
        </div>
      </div>
      ) || (
        <div className={'d-flex actions'}>
          <div onClick={() => handleClick()}>
            <FontAwesomeIcon icon={faEye}/>
          </div>
        </div>
      )}
    </div>
  );
};

export { Word };