import React, {useState, useEffect} from 'react';
import {useHistory} from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {Loader} from "../components/Loader";

const Home = (props) => {

  const [categories, setCategories] = useState(props.categories);
  const history = useHistory();

  useEffect(() => {
    setCategories(props.categories);
  }, [props.categories]);

  function handleClick(url) {
    history.push(url);
  }

  return (
    <main>
      <div>
        <div className={'title-homepage'}>Categories</div>

        <div className={'categories'}>

          <div className={'category'} style={{backgroundColor: '#222'}} onClick={() => handleClick('/rules/reading')}>
            <FontAwesomeIcon icon={'book-open'} />
            <div className={'english'}>Reading Rules</div>
          </div>

          {(categories && Array.isArray(categories)) && categories.map((category, i) => {
            return (
              <div key={i} className={'category'} style={{backgroundColor: category.color}} onClick={() => handleClick('/category/' + category.id)}>
                <FontAwesomeIcon icon={category.icon} />
                <div className={'english'}>{ category.english }</div>
                <div className={'phonetic'}>{ category.phonetic }</div>
              </div>
            )
          })}

          {(props.loading && categories.length === 0) ? <Loader loading={props.loading}/> : null}

        </div>
      </div>
    </main>
  );
};

export default Home;