import React from 'react';

const Input = (props) => {

  return (
    <div>
      <input type={props.type ? props.type : 'text'}
             name={props.name}
             value={props.value}
             placeholder={props.placeholder}
             autoComplete={props.autocomplete ?? 'on'}
             onChange={(e) => props.onChange(e)}
             className={((props.error) ? ' is-invalid' : '')}/>

      <div className={'invalid-form ' + (((props.error) && 'invalid-form--visible') || '')}>{(props.error) && props.error[0]}</div>
    </div>
  );
};

export { Input };