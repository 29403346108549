import React, {useState, useEffect} from 'react';
import useForm from '../hooks/useForm';
import { Input } from '../components/Forms';

const SearchForm = (props) => {

  const initialState = {
    search: '',
  };

  const [form, setForm] = useForm(initialState);
  const [words] = useForm(props.words);
  const [errors] = useState([]);

  useEffect(() => {
    props.callback(search(form.search));
  }, [form.search]);

  function search(term) {
    return words.filter(({english, search, thai}) => {
      return english.toLowerCase().indexOf(term.toLowerCase()) > -1 ||
        search.toLowerCase().indexOf(term.toLowerCase()) > -1 ||
        thai.toLowerCase().indexOf(term.toLowerCase()) > -1
    })
  }

  return (
    <form className={'search'}>
      <Input type={'search'} name={'search'} placeholder={'search'} value={form.search} onChange={setForm} error={errors.search} autocomplete={'off'}/>
    </form>
  );
};

export default SearchForm;