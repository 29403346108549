import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { useEffect } from 'react';
import {faHome, faUser, faSearch} from '@fortawesome/free-solid-svg-icons'
import {NavLink} from "react-router-dom";
import {useUserContext} from "../context/UserContext";
import axios from "axios";
import Api from "../services/Api";
import {useHistory} from "react-router-dom";

function Menu() {

  const {setUser} = useUserContext();
  const history = useHistory();

  // Auto Login
  useEffect(() => {
    async function getUser() {
      if(localStorage.getItem('token')) {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');
        const dataUser = await Api.getUser();
        if(dataUser.data) {
          console.log(dataUser.data);
          setUser(dataUser.data);
        } else {
          localStorage.removeItem('token');
        }
      }
    }

      getUser();
  }, [setUser]);

  function handleClick(e, route) {
    e.preventDefault();
    history.replace(route);
  }

  return (
    <menu id="menu">
        <div id="tabs">
          <NavLink exact to="/" activeClassName="selected" onClick={(e) => handleClick(e, '/')}>
            <FontAwesomeIcon icon={faHome}/>
            <span>Home</span>
          </NavLink>
          <NavLink to="/search" activeClassName="selected" onClick={(e) => handleClick(e, '/search')}>
            <FontAwesomeIcon icon={faSearch}/>
            <span>Search</span>
          </NavLink>
        </div>
    </menu>
  );
}

export default Menu;