import axios from 'axios';
import { resolve } from './resolve.js';

class Api {
  // static url = "http://api.learn-thai.fr";
  static url = "https://api.learnthai.fr";

  async csrf() {
    return await resolve(axios.get(Api.url + '/sanctum/csrf-cookie').then(res => res.data));
  }

  async login(data) {
    return await resolve(axios.post(Api.url + '/login', data).then(res => res.data));
  }

  async getCategories() {
    return await resolve(axios.get(Api.url + '/categories').then(res => res.data));
  }

  async search(data) {
    return await resolve(axios.post(Api.url + '/search', data).then(res => res.data));
  }

  async getUser() {
    return await resolve(axios.get(Api.url + '/user').then(res => res.data));
  }

  async getWords(page = 1) {
    return await resolve(axios.get(Api.url + '/words?page=' + page).then(res => res.data));
  }

  async getWord(data) {
    return await resolve(axios.get(Api.url + '/words/' + data).then(res => res.data));
  }

  async storeWord(data) {
    return await resolve(axios.post(Api.url + '/words', data).then(res => res.data));
  }

  async destroyWord(data) {
    return await resolve(axios.delete(Api.url + '/words/' + data).then(res => res.data));
  }
}

axios.defaults.withCredentials = true;

export default new Api();