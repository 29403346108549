import React, {useEffect, useState } from 'react';
import { createPortal } from "react-dom";

function Modal(props) {
    const [open, setOpen] = useState(props.open);
    const [animation, setAnimation] = useState(false);
    const body = document.body;

    function closeModal() {
        setAnimation(false);
        setTimeout(function(){ props.close(false); setOpen(false); }, 100);
    }

    function openModal() {
        setOpen(props.open);
        setTimeout(function(){ setAnimation(true); }, 100);
    }

    useEffect( () => {
        if(!props.open) {
            closeModal();
        } else {
            openModal();
        }
    }, [props.open]);

    return createPortal(
        <div onClick={closeModal}>
            <div className={'modal fade' + (open ? ' d-block' : '') + (animation ? ' show' : '')}>
                <div className="modal-dialog flex-column" role="document">
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                        {props.children}
                    </div>
                    <div className="surrender-modal text-underline-hover">
                        Cancel
                    </div>
                </div>
            </div>
            {open &&
                <div className={'modal-backdrop fade' + (animation ? ' show' : '')}></div>
            }
        </div>
        ,
        body
    )
}

export default Modal;
