import React, {useEffect, useState, forwardRef} from 'react';
import Api from "../services/Api";
import {Loader} from "../components/Loader";
import {Word} from "../components/Word";
import SearchForm from "../forms/SearchForm";
import Modal from "../components/Modal";
import useModal from "../hooks/useModal";
import {Button} from "../components/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const ListWords = forwardRef((props, ref) => {

  const [words, setWords] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const { open, openModal, closeModal } = useModal();
  const [currentWord, setCurrentWord] = useState({id: '', index: ''});
  const [hideThaiWords, setHideThaiWords] = useState(false);

  useEffect(() => {
    search();
  }, [setWords]);

  const search = async (form = null) => {
    setLoading(true);

    let wordsStorage = JSON.parse(localStorage.getItem('words'));

    if(wordsStorage) {
      setWords(wordsStorage);
      setLoading(false);
    }

    if(navigator.onLine) {
      const data = await Api.search(form);
      if (data.error) {
        setLoading(false);
        if (data.error.response && data.error.response.data) {
          // setErrors(data.error.response.data.errors || {});
        }
      } else {
        setWords(data.data);
        localStorage.setItem('words', JSON.stringify(data.data));
        setLoading(false);
        setLoaded(true);
      }
    }
  };

  async function destroyWord() {
    setLoading(true);
    const word = await Api.destroyWord(currentWord.id);
    if (word.error) {
      setLoading(false);
    } else {
      setLoading(false);
      words.splice(currentWord.index, 1);
      closeModal();
    }
  }

  function openDeleteModal(id, index) {
    setCurrentWord({id: id, index: index});
    openModal();
  }

  if(!loaded) return <main><Loader loading={true}/></main>;

  return (
    <div id={'list-words'}>

      <div className={'small-text cursor-pointer mt-1'} onClick={() => setHideThaiWords(!hideThaiWords)}>
        {(!hideThaiWords) ? (<div><FontAwesomeIcon icon={'eye-slash'} /> Hide thai</div>) : (<div><FontAwesomeIcon icon={'eye'} /> Show thai</div>) }
      </div>

      <SearchForm callback={(e) => setWords(e)} words={words}/>

      <div className={'list'}>
        {(words && Array.isArray(words)) && words.map((word, i) => {
          return (
            <Word key={i} word={word} openModal={() => openDeleteModal(word.id, i)} hideThai={hideThaiWords}/>
          )
        })}
      </div>

      <Loader loading={loading}/>

      <Modal open={open} close={closeModal}>
        <div className={'text-center mb-2'}>Are you sure ?</div>
        <div className={'d-flex justify-content-center'}>
          <Button value="No" onClick={closeModal} className={'btn-red btn-confirm'}>No</Button>
          <Button value="Yes" onClick={destroyWord} className={'btn-blue btn-confirm'}>Yes</Button>
        </div>
      </Modal>
    </div>
  );
});

export default ListWords;