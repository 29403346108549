import './App.scss';
import React, {useState, useEffect} from 'react';
import {UserProvider} from "./context/UserContext";
import Home from "./pages/Home";
import Menu from "./layouts/Menu";
import Header from "./layouts/Header";
import {
  BrowserRouter as Router, Route,
} from "react-router-dom";
import Word from "./pages/Word";
import Search from "./pages/Search";
import {library} from '@fortawesome/fontawesome-svg-core';
import * as Icons from '@fortawesome/free-solid-svg-icons';
import Category from "./pages/Category";
import ReadingRules from "./pages/ReadingRules";
import Api from "./services/Api";
import {InstallPWA} from "./components/InstallPWA";

function App() {

  // Font Awesome all icons imported
  const iconList = Object.keys(Icons)
    .filter((key) => key !== 'fas' && key !== 'prefix')
    .map((icon) => Icons[icon]);

  library.add(...iconList);

  const [user] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchIsLoaded, setSearchIsLoaded] = useState(false);

  useEffect(() => {
    getCategories();
  }, [setCategories]);

  async function getCategories() {
    let categoriesStorage = JSON.parse(localStorage.getItem('categories'));

    if(categoriesStorage) {
      setCategories(categoriesStorage);
    }

    if(navigator.onLine) {
      setLoading(true);
      const data = await Api.getCategories();
      if (data.error) {
        setLoading(false);
      } else {
        setCategories(data.data);
        localStorage.setItem('categories', JSON.stringify(data.data));
        setLoading(false);
      }
    }
  }

  return (
    <UserProvider user={user}>
      <div className="App">
        <Header/>
        <Router>
          <Route exact path={["/search/words/:idWord", "/account/words/:idWord", "/category/:idCategory/words/:idWord"]} children={({match, ...rest}) => (
            <div className={'animate-left-to-right ' + (match ? "show" : "")}>
              <Word categories={categories}/>
            </div>
          )}/>
          <Route path={["/rules/reading"]} children={({match, ...rest}) => (
            <div className={'animate-bottom-to-top ' + (match ? "show" : "")}>
              <ReadingRules/>
            </div>
          )}/>
          <Route path={["/category/:idCategory"]} children={({match, ...rest}) => (
            <div className={'animate-bottom-to-top ' + (match ? "show" : "")}>
              <Category categories={categories} display={(!!match)}/>
            </div>
          )}/>
          <Route exact path={["/search", "/search/words/:idWord"]} children={({ match, ...rest }) => (
            <div className={'animate ' + (match ? "show" : "")}>
              <Search isLoaded={searchIsLoaded ? true : (match !== null)} loaded={() => setSearchIsLoaded(true)}/>
            </div>
          )} />
          <Route exact path={["/", "/category/:idCategory"]} children={({ match, ...rest }) => (
            <div className={'animate ' + (match ? "show" : "")}>
              <Home categories={categories} loading={loading}/>
            </div>
          )} />
          <InstallPWA/>
          <Menu/>
        </Router>
      </div>
    </UserProvider>
  );
}

export default App;
