import React, {useState, createContext, useContext} from 'react';

export const UserContext = createContext([]);

export const UserProvider = props => {
    const [user, setUser] = useState(props.user);

    return (
        <UserContext.Provider value={[user, setUser]}>
            { props.children }
        </UserContext.Provider>
    );
};

export const useUserContext = () => {
    const [user, setUser] = useContext(UserContext);

    return { user, setUser };
};