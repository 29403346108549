import React from 'react';

const Loader = (props) => {
  return (
    <div className={props.loading ? 'd-block' : 'd-none'}>
      <div className="lds-ripple">
        <div></div>
        <div></div>
      </div>
    </div>
  );
}

export { Loader };