import {useState} from "react";

function useForm(initialState) {
    const [fields, setValues] = useState(initialState);

    return [
        fields,
        function (event) {
            if (event.target) {
                setValues({
                    ...fields,
                    [event.target.name]: event.target.value
                });
            } else {
                setValues(initialState);
            }
        }
    ];
}

export default useForm;
