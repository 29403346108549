import React, {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {useReactPWAInstall} from "../hooks/ReactPWAInstall";

const InstallPWA = () => {
  const { pwaInstall, supported, isInstalled } = useReactPWAInstall();
  const [displayBtn, setDisplayBtn] = useState(true);

  const handleClick = () => {
    pwaInstall({
      title: "Install App now !",
      logo: '/thai-flag.png',
      features: (
        <ul>
          <li>Learn more</li>
          <li>Remember a word you forget</li>
          <li>Help you to write Thai</li>
        </ul>
      ),
      description: "This is a very good app to help you in your daily Thai !",
    })
      .then(() => null)
      .catch(() => null);
  };

  const closeBtn = () => {
    setDisplayBtn(false);
  };

  if(!supported() || isInstalled()) return null;

  if(!displayBtn) return null;

  return (
    <span className={'install-pwa'}>
      <button type="button" onClick={handleClick}>
        <div>Install App now !</div>
      </button>

      <div className={'close-btn'} onClick={closeBtn}>
        <FontAwesomeIcon icon={faTimes}/>
      </div>
    </span>
  );
};

export { InstallPWA };