import React, {useEffect, useState, forwardRef} from 'react';
import {Word} from "../components/Word";
import SearchForm from "../forms/SearchForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faChevronDown} from "@fortawesome/free-solid-svg-icons";
import {useParams, useHistory } from "react-router-dom";
import {Loader} from "../components/Loader";

const Category = forwardRef((props, ref) => {

  let { idCategory } = useParams();
  const [words, setWords] = useState([]);
  const [category, setCategory] = useState([]);
  const history = useHistory();
  const [display, setDisplay] = useState(props.display);
  const [hideThaiWords, setHideThaiWords] = useState(false);

  useEffect(() => {
    if(props.display) {
      let category = props.categories.find(x => x.id === parseInt(idCategory));
      setCategory(category);
      if(typeof category !== 'undefined') {
          setWords(category.words);
      }
    } else {
      setWords([]);
    }
    setDisplay(props.display);
  }, [props.display, hideThaiWords, idCategory, props.categories]);

  function handleClick() {
    history.push('/');
  }

  if(!category || category.length === 0) return <main><Loader loading={true}/></main>;

  return (
    <main style={category ? {backgroundColor: category.color} : {}}>
      {((display) &&
          <div>
            <div className={'back-to-page'}>
              <div onClick={() => handleClick()}><FontAwesomeIcon icon={faChevronDown}/></div>
            </div>
            <div id={'list-words-admin'}>
              <div className={'d-flex justify-content-center align-items-center flex-column mb-1'}>
                <div className={'d-flex align-items-center'}>
                  <FontAwesomeIcon icon={category.icon}/>
                  <div className={'bold ml-1'}>{category.english}</div>
                </div>
                <div className={'d-flex'}>
                  <div className={'phonetic'}>{category.phonetic}</div>
                  <div className={'phonetic ml-1'}>({category.thai})</div>
                </div>
              </div>
              <SearchForm callback={(e) => setWords(e)} words={words}/>
              <div className={'list'}>
                {(words && Array.isArray(words)) && words.map((word, i) => {
                  return (
                    <Word key={i} word={word} hideThai={hideThaiWords}/>
                  )
                })}
              </div>
            </div>
            <div className={'small-text cursor-pointer mt-1'} onClick={() => setHideThaiWords(!hideThaiWords)}>
              {(!hideThaiWords) ? (<div><FontAwesomeIcon icon={'eye-slash'} /> Hide thai</div>) : (<div><FontAwesomeIcon icon={'eye'} /> Show thai</div>) }
            </div>
          </div>
      )}
    </main>
  );
});

export default Category;