import React from 'react';

const Button = (props) => {

  return (
    <div>
      <button type="submit"
              value={props.value}
              className={(props.className ?? 'btn-blue') + (props.loading ? ' disabled' : '')}
              disabled={props.loading}
              onClick={props.onClick}>
        {props.children}
      </button>

      <div className={'invalid-form ' + (((props.error) && 'invalid-form--visible') || '')}>{(props.error) && props.error[0]}</div>
    </div>
  );
};

export { Button };