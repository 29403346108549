import React from 'react';
import ListWords from "./ListWords";

const Search = (props) => {
  return (
    <main>
      <ListWords/>
    </main>
  );
};

export default Search;